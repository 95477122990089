import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import api from '../../../services/apiIdz';

// Style
import './DetalhesVeiculo.scss';

// Components
import PropostaModal from '../PropostaModal';
import VideoModal from '../../Home/VideoModal';


function DetalhesVeiculo({ id, ano, km, blindagem, preco, imagem, detalhes, marca, idzEvent, navigation, videos, status }) {
    const [showModal, setShowModal] = useState(false);
    const [idVeiculo, setIdVeiculo] = useState(null);

    const handleShow = (id) => {
        setIdVeiculo(id);
        setShowModal(true);
    };
    const handleClose = () => setShowModal(false);

    const handleShareClick = async () => {
        try {
            // Verifica se o navegador suporta a API Web Share
            if (navigator.share) {
                await navigator.share({
                    title: 'Título do compartilhamento',
                    text: 'Texto do compartilhamento',
                    url: window.location.href
                });
            } else {
                // Se o navegador não suportar a API Web Share, você pode fornecer uma mensagem alternativa ou uma funcionalidade de compartilhamento alternativa
                console.log('Navegador não suporta API Web Share');
            }
        } catch (error) {
            console.error('Erro ao compartilhar:', error);
        }
    };

    const [showModalVideo, setShowModalVideo] = useState(false);
    const [videoId, setVideoId] = useState('');

    const closeModal = () => setShowModalVideo(false);

    const openModal = (id) => {
        console.log("video:", id);
        setVideoId(id);
        setShowModalVideo(true);
    };

    return (
        <>
            <PropostaModal id={idVeiculo} showModal={showModal} idzEvent={idzEvent} navigation={navigation} handleClose={handleClose} />
            <section className='detalhesVeiculo'>
                <Container>
                    <Row className='justify-content-md-center'>
                        <Col xs={12} sm={12} md={12} lg={5}>
                            <h4>{marca}</h4>
                            <h3>{detalhes && detalhes.titulo}</h3>
                            {status === "Vendido" ? <span className='Vendido'><Icon className="icons" icon="lets-icons:check-fill" /> Veículo Vendido</span> : ''}
                            <p>{detalhes && detalhes.chamada}</p>
                            <ul>
                                <li>
                                    <span>Ano</span>{ano}
                                </li>
                                <li>
                                    <span>KM</span>{km}
                                </li>

                                {blindagem && blindagem.blindado === 1 && (
                                    <>
                                        <li>
                                            <span>Tipo de Blindagem</span>{blindagem.tipo_blindagem}
                                        </li>
                                        {blindagem.tipo_vidro &&
                                        <li>
                                            <span>Tipo de Vidros</span>{blindagem.tipo_vidro} 
                                            {blindagem.garantia_vidro && <strong>(Garantia de {blindagem.garantia_vidro})</strong>}
                                        </li>
                                        }
                                    </>
                                )}
                            </ul>
                            {status != "Vendido" ? <>
                            <div className="valor">
                                <span>Valor</span>
                                {preco === 'R$ 0,00' ? (
                                    <h3>Subconsulta</h3>
                                ) : (
                                    <h3>{preco}</h3>
                                )}
                            </div>
                            </> : ''}
                            <div className="acoes">
                                <Link className='btn btn-primary' variant="primary" onClick={() => handleShow(id)}>{status != "Vendido" ? 'faça a sua proposta' : 'Curtiu? Encomende o seu!'}</Link>
                                <Link onClick={handleShareClick} className='btn btn-icon' to="/"><i><Icon className="icons" icon="ooui:share" /></i></Link>
                            </div>
                            
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={{ span: 6, offset: 1 }}>
                            {imagem && imagem.imagem && (<div className='imgDetalhe' style={{ background: `url(${api.defaults.baseURL}${imagem.imagem})  no-repeat center / cover` }}>
                                {blindagem && blindagem.blindado === 1 && (<span>blindado</span>)}
                                {videos != "" && (
                                <div className="playVideos" onClick={() => openModal(videos)}>
                                    <div className='play' style={{ background: `url(${api.defaults.baseURL}${imagem.imagem})  no-repeat center / cover` }}><i><Icon icon="heroicons-outline:play" /></i></div>
                                    <p>ASSISTA AO VÍDEO</p>
                                </div>
                                )}
                            </div>)}
                        </Col>
                    </Row>
                    <Row className='justify-content-md-center'>
                        <a href="#galeria" className='btn btn-down'><i><Icon className="icons" icon="iconoir:arrow-down" /></i></a>
                    </Row>
                </Container>
            </section>
            <VideoModal show={showModalVideo} handleClose={closeModal} videoId={videoId} />
        </>

    );
}

export default DetalhesVeiculo;
