import React, { useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";

// Style
import './Video.scss';

function Video() {
    useEffect(() => {
        // Carregar o script de incorporação do Instagram após o componente ser montado
        const script = document.createElement('script');
        script.async = true;
        script.src = "//www.instagram.com/embed.js";
        document.body.appendChild(script);

        return () => {
            // Limpar o script quando o componente for desmontado
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <section className='Video'>
                <Container>
                    <Row>
                        <Col xs={12} sm={6} md={12} lg={6} className='boxVideo'>
                            <div className='textVideo'>
                                <i></i>
                                <h5>Inovação, leveza e segurança</h5>
                                <h3><strong>Vídeo processo</strong><br />  de blindagem</h3>
                                <p>Aqui na 1021 Motors, garantimos o máximo de segurança e inovação no processo de blindagem dos seus veículos. <br /><br />
                                    Cada etapa foi cuidadosamente desenvolvida para garantir uma blindagem eficiente, mantendo o conforto e a integridade do carro. Desde o estudo dos pontos vulneráveis até a escolha dos melhores materiais, nossa equipe de especialistas acompanha de perto cada detalhe para proporcionar a melhor experiência aos nossos clientes. Assista ao vídeo e entenda como transformamos tecnologia de ponta em proteção para você e sua família.</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={12} lg={6} className='bgVideo'>
                            <div className='imgVideo'>
                                {/* Embed do Instagram Reel */}
                                <blockquote
                                    className="instagram-media"
                                    data-instgrm-permalink="https://www.instagram.com/reel/CqlCAuZIDGZ/"
                                >
                                    <a
                                        href="https://www.instagram.com/reel/CqlCAuZIDGZ/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Ver este post no Instagram
                                    </a>
                                </blockquote>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Video;
