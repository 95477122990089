import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Row, Col, Container } from 'react-bootstrap'; 
import api from '../../../services/apiIdz';
import { useMediaQuery } from 'react-responsive';

// Styles
import './Galeria.scss';

function Galeria({ fotos }) {
    const fotosArray = fotos ?? [];
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const openLightbox = (index) => {
        setCurrentPhotoIndex(index);
        setLightboxIsOpen(true);
    };

    // Função para calcular o número de colunas com base no número total de fotos
    const calculateColumns = (totalPhotos) => {
        if (totalPhotos <= 4) {
            return 2; // Se houver 4 ou menos fotos, use 2 colunas
        } else if (totalPhotos <= 6) {
            return 3; // Se houver 6 ou menos fotos, use 3 colunas
        } else {
            return isMobile ? 2 : 4; // Caso contrário, use 4 colunas
        }
    };

    // Dividir as fotos em linhas com um número variável de colunas
    const splitPhotosIntoRows = (photos, columns) => {
        const rows = [];
        for (let i = 0; i < photos.length; i += columns) {
            rows.push(photos.slice(i, i + columns));
        }
        return rows;
    };

    // Calcular o número de colunas com base no número total de fotos
    const columns = calculateColumns(fotosArray.length);

    // Dividir as fotos em linhas com o número de colunas calculado
    const photoRows = splitPhotosIntoRows(fotosArray, columns);

    const openLightboxWithPhoto = (index) => {
        setCurrentPhotoIndex(index);
        setLightboxIsOpen(true);
    };

    return (
        <>
            <Container className='galeriaContainer'>
            <div className="galeria">
                {photoRows.map((row, rowIndex) => (
                    <Row key={rowIndex} className="row">
                        {row.map((foto, columnIndex) => (
                            <Col key={rowIndex * columns + columnIndex} xs={12 / row.length} lg={12 / row.length}>
                                {console.log("row:" , row.length)}
                                <div
                                    key={rowIndex * columns + columnIndex}
                                    className="photo"
                                    style={{ background: `url(${api.defaults.baseURL}${row.length == 1 ? foto.imagem : foto.thumb}) no-repeat center / cover` }}
                                    onClick={() => openLightbox(rowIndex * columns + columnIndex)}
                                ></div>
                            </Col>
                        ))}
                    </Row>
                ))}
            </div>
            <button className='btn btn-tertiary' onClick={() => openLightboxWithPhoto(0)}>Ver Todas as Fotos</button>
            </Container>
            {lightboxIsOpen && (
                <Lightbox
                    mainSrc={`${api.defaults.baseURL}${fotosArray[currentPhotoIndex].imagem}`}
                    nextSrc={`${api.defaults.baseURL}${fotosArray[(currentPhotoIndex + 1) % fotosArray.length].imagem}`}
                    prevSrc={`${api.defaults.baseURL}${fotosArray[(currentPhotoIndex + fotosArray.length - 1) % fotosArray.length].imagem}`}
                    onCloseRequest={() => setLightboxIsOpen(false)}
                    onMovePrevRequest={() =>
                        setCurrentPhotoIndex((currentPhotoIndex + fotosArray.length - 1) % fotosArray.length)
                    }
                    onMoveNextRequest={() =>
                        setCurrentPhotoIndex((currentPhotoIndex + 1) % fotosArray.length)
                    }
                />
            )}
           
        </>
    );
}

export default Galeria;
